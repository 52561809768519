<template>
  <div class="" :class="{ 'row align-items-center': !options.labelStacked }">
    <!------------ START: Label ------------>
    <label
      class="col-form-label d-flex justify-content-start align-items-center flex-wrap align-self-start"
      :class="{ 'col-xl-3 col-lg-3 mt-2': !options.labelStacked }"
    >
      <span class="flex-grow-1">
        {{ getSnippet(field.label) }}
        <!------------ START: Required asterisk ------------>
        <span
          v-if="
            field.required || (field.validations && field.validations.required)
          "
          class="text-danger"
          >*</span
        >
        <!------------ END: Required asterisk ------------>
        <!------------ START: Help text ------------>
        <i
          v-if="field.help"
          v-b-tooltip.noninteractive.top="helpText"
          class="fal fa-question-circle ml-1"
        />
        <!------------ END: Help text ------------>
      </span>
      <!------------ START: Slot label append ------------>
      <span>
        <slot name="label-append"></slot>
      </span>
      <!------------ END: Slot label append ------------>
    </label>
    <!------------ END Label ------------>
    <div :class="{ 'col-lg-9 col-xl-6': !options.labelStacked }">
      <!------------ START: Input group-->
      <div v-if="inputGroup" ref="inputGroup" class="input-group">
        <!------------ START: Copy ------------>
        <div v-if="field.copy" class="input-group-prepend">
          <span
            v-b-tooltip.noninteractive.top="$t('formHelper.copy')"
            class="input-group-text cursor-pointer"
            @click="copyValue"
          >
            <i class="fal fa-copy" />
          </span>
        </div>
        <!------------ END: Copy ------------>
        <!------------ START: Prepend icon ------------>
        <div v-if="field.prependIcon" class="input-group-prepend">
          <span class="input-group-text">
            <i :class="[field.prependIcon]" />
          </span>
        </div>
        <!------------ END: Prepend icon ------------>
        <!------------ START: Prepend text ------------>
        <div v-if="field.prepend" class="input-group-prepend">
          <span class="input-group-text">{{ getSnippet(field.prepend) }}</span>
        </div>
        <!------------ END: Prepend text ------------>
        <!------------ START: Slot default ------------>
        <slot></slot>
        <!------------ END: Slot default ------------>
        <!------------ START: Append text ------------>
        <div v-if="field.append" class="input-group-append">
          <span class="input-group-text">{{ getSnippet(field.append) }}</span>
        </div>
        <!------------ END: Append text ------------>
        <!------------ START: Append icon ------------>
        <div v-if="field.appendIcon" class="input-group-append">
          <span class="input-group-text">
            <i :class="[field.appendIcon]" />
          </span>
        </div>
        <!------------ END: Append icon ------------>
      </div>
      <!------------ END: Input group-->
      <!------------ START: Slot default ------------>
      <slot v-else></slot>
      <!------------ END: Slot default ------------>
      <!------------ START: Slot hints ------------>
      <span class="form-text text-muted ml-1">
        <slot name="hints"></slot>
      </span>
      <!------------ END: Slot hints ------------>
    </div>
  </div>
</template>

<script>
import { getSnippet } from "@/components/Tools/FormHelper/Helper/functions";

export default {
  name: "FieldWrapper",
  components: {},
  inject: ["options"],
  props: {
    field: {
      type: Object,
      default: () => {}
    },
    inputGroup: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    // Return final snippet for help text
    helpText: function () {
      if (!this.field.help) {
        return "";
      }
      // "" + Snippet : Fix for eslint error
      return "" + this.$t(`${this.options.snippetPrefix}.${this.field.help}`);
    }
  },
  mounted() {},
  methods: {
    // Get text as snippet by given prefix
    getSnippet(text) {
      return getSnippet(text, this.options.snippetPrefix);
    },
    // Copy value to clipboard
    copyValue() {
      this.$parent.copyValue();
    }
  }
};
</script>
